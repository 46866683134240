import { Client } from '../../fetch'
import * as URLUtils from '../../url'

export default class MountainConditionReportClient {
    constructor(base) {
        const url = URLUtils.buildPath(base, 'mcr')

        this.client = new Client(url)
    }
    report(id) {
        return this.client.get(id)
    }
    reports() {
        return this.client.get()
    }
}
