import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useToggle } from 'hooks'
import { Expand } from 'components/button'
import { noop } from '@avcan/utils/function'
import css from './Layer.module.css'

Layer.propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.element,
}

export default function Layer({ title, visible, disabled, icon, onClick, children }) {
    const [expanded, toggle] = useToggle(false)
    const className = clsx({
        [css.Layer]: true,
        [css.Visible]: visible,
        [css.Disabled]: disabled,
    })
    function handleExpandClick(event) {
        event.stopPropagation()

        toggle()
    }

    return (
        <div className={className}>
            {/* We have the checkbox to make these accessible so we shouldn't need to do extra work to make this accessible */}
            {/* eslint-disable-next-line */}
            <div className={css.Header} onClick={disabled ? noop : onClick}>
                {icon}
                <span className={css.Title}>{title}</span>
                {Boolean(children && !disabled) && <Expand chevron expanded={expanded} onClick={handleExpandClick} />}
                <input type="checkbox" checked={visible} aria-label={`${title}`} readOnly />
            </div>
            {Boolean(expanded && !disabled) && <div className={css.Content}>{children}</div>}
        </div>
    )
}
