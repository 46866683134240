import * as ObjectUtils from './object'

export function create(values = {}, filterCreators = new Map()) {
    function filterEntry([key, value]) {
        if (!filterCreators.has(key)) {
            return false
        }

        if (Array.isArray(value)) {
            return value.length > 0
        }

        if (value instanceof Set) {
            return value.size > 0
        }

        return true
    }
    function createOne([key, value]) {
        return filterCreators.get(key).call(null, value)
    }

    return Object.entries(ObjectUtils.clean(values)).filter(filterEntry).map(createOne)
}
