import { endOfDay } from 'date-fns'

export function setUTCOffset(date, offset) {
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60 + offset)

    return date
}

export function startOfSeason(date = new Date()) {
    const year = date.getFullYear()
    const octoberFirst = new Date(year, 9, 1)

    return date < octoberFirst ? new Date(year - 1, 9, 1) : octoberFirst
}

export function endOfSeason(date = new Date()) {
    const year = date.getFullYear()
    const september30 = endOfDay(new Date(year, 8, 30))

    return date > september30 ? new Date(year + 1, 8, 30) : september30
}

export function isValid(date) {
    if (!date) {
        return false
    }

    // https://www.geeksforgeeks.org/how-to-check-a-date-is-valid-or-not-using-javascript/
    // eslint-disable-next-line no-self-compare
    return date.getTime() === date.getTime()
}

export function split(date, utc = false) {
    if (utc) {
        return [
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds(),
        ]
    }

    return [
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
    ]
}
